<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn
            v-if="canEditSetupTables"
            class="float-right"
            color="green"
            dark
            @click="onSubmit"
            data-cy="vehicle-type-save-btn"
          >
            save
          </v-btn>
          <v-btn
            v-if="me.is.superAdmin && value.synced"
            class="float-right mr-2"
            color="primary"
            @click="hide()"
            :disabled="value.cannotDelete"
          >
            <v-icon class="mr-2" small>mdi-{{ value.hidden ? 'eye' : 'eye-off' }}</v-icon>
            {{ value.hidden ? 'Unhide' : 'Hide' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="value.id > 0 && !canEditSetupTables" data-cy="vehicle-type-form">
        <v-container>
          <v-alert v-if="value.synced" outlined type="info" text>
            This Vehicle Type is synced with EZRouting. Some fields will not be able to be modified here.
          </v-alert>
          <v-alert v-else-if="!value.id && isRoutingClient" outlined type="info" text>
            This Vehicle Type will only be avilable in EZActivityTrips. If you would like this Vehicle Type to be
            available in EZRouting, please add it there first and it will be synced with EZActivityTrips.
          </v-alert>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="6">
              <v-text-field
                :value="value.name"
                :readonly="value.synced"
                v-model.trim="$v.form.name.$model"
                label="Type"
                required
                :error-messages="handleErrors($v.form.name)"
                @blur="$v.form.name.$touch()"
                data-cy="vehicle-type-name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                :readonly="value.synced"
                :value="value.active"
                v-model="$v.form.active.$model"
                label="Active"
                required
                @change="$v.form.active.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="3">
              <v-checkbox
                :value="value.staffUse"
                v-model="$v.form.staffUse.$model"
                label="Staff Use"
                required
                @change="$v.form.staffUse.$touch()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                :value="value.autoAssign"
                v-model="$v.form.autoAssign.$model"
                label="Auto Assign"
                required
                @change="$v.form.autoAssign.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense v-if="$v.form.autoAssign.$model">
            <v-col cols="12" md="12" class="mb-0">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Auto Assign for Trip Types"
                :value="value.autoAssignTripTypes"
                v-model="$v.form.autoAssignTripTypes.$model"
                :items="tripTypes"
                item-text="name"
                item-value="id"
                outlined
                class="mb-0"
                multiple
              >
                <template v-slot:selection="{ item, parent }">
                  <v-chip>
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="6">
              <v-checkbox
                :value="value.overrideRate"
                v-model="$v.form.overrideRate.$model"
                label="Override Trip Type & Event Rate?"
                required
                @change="$v.form.overrideRate.$touch()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-show="$v.form.overrideRate.$model"
                :value="value.vehicleRate"
                v-model.trim="$v.form.vehicleRate.$model"
                label="Vehicle Rate"
                required
                type="number"
                min="0"
                prepend-inner-icon="mdi-currency-usd"
                :error-messages="handleErrors($v.form.vehicleRate)"
                @blur="$v.form.vehicleRate.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="6">
              <v-checkbox
                :value="value.available"
                v-model="$v.form.available.$model"
                label="Available to End User on Trip"
                required
                @change="$v.form.available.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';

const newVehicleType = {
  name: '',
  active: true,
  staffUse: false,
  autoAssign: false,
  overrideRate: false,
  vehicleRate: 0,
  available: false,
};

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    id: null,
    form: newVehicleType,
    saved: false,
  }),
  validations() {
    return {
      form: {
        name: { required },
        active: {},
        staffUse: {},
        autoAssign: {},
        autoAssignTripTypes: {},
        overrideRate: {},
        vehicleRate: {
          required: requiredIf(() => {
            return !!this.form.overrideRate;
          }),
        },
        available: {},
      },
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('app', ['isRoutingClient']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newVehicleType;
        this.$emit('closeForm');
      }
    },
    handleInput(address) {
      this.address = address;
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    async hide() {
      const message = this.value.hidden
        ? `Are you sure you want to unhide ${this.value.name}?`
        : `Are you sure you want to hide ${this.value.name}? The Vehicle Type will be unavailable in EZActivityTrips but it will not effect EZRouting.`;
      const ok = await this.$myconfirm(message);
      if (!ok) return;
      this.$emit('submit', { id: this.id, ...this.$v.form.$model, hidden: !this.value.hidden });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && this.$v.form.$model.name) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.active != this.value.active ||
        this.$v.form.$model.staffUse != this.value.staffUse ||
        this.$v.form.$model.autoAssign != this.value.autoAssign ||
        this.$v.form.$model.overrideRate != this.value.overrideRate ||
        this.$v.form.$model.vehicleRate != this.value.vehicleRate ||
        this.$v.form.$model.available != this.value.available
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newVehicleType,
    },
    tripTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else this.form = { ...newVehicleType, ...value };
      },
    },
    form: {
      deep: true,
      handler: function (value) {
        this.$emit('formChanges', value);
      },
    },
  },
};
</script>
