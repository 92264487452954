var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            color: "green",
                            dark: "",
                            "data-cy": "vehicle-type-save-btn",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                  _vm.me.is.superAdmin && _vm.value.synced
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            color: "primary",
                            disabled: _vm.value.cannotDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hide()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [
                              _vm._v(
                                "mdi-" +
                                  _vm._s(_vm.value.hidden ? "eye" : "eye-off")
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.value.hidden ? "Unhide" : "Hide") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              attrs: {
                readonly: _vm.value.id > 0 && !_vm.canEditSetupTables,
                "data-cy": "vehicle-type-form",
              },
            },
            [
              _c(
                "v-container",
                [
                  _vm.value.synced
                    ? _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "info", text: "" } },
                        [
                          _vm._v(
                            " This Vehicle Type is synced with EZRouting. Some fields will not be able to be modified here. "
                          ),
                        ]
                      )
                    : !_vm.value.id && _vm.isRoutingClient
                    ? _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "info", text: "" } },
                        [
                          _vm._v(
                            " This Vehicle Type will only be avilable in EZActivityTrips. If you would like this Vehicle Type to be available in EZRouting, please add it there first and it will be synced with EZActivityTrips. "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.name,
                              readonly: _vm.value.synced,
                              label: "Type",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.name
                              ),
                              "data-cy": "vehicle-type-name",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              readonly: _vm.value.synced,
                              value: _vm.value.active,
                              label: "Active",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.active.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.active.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.active, "$model", $$v)
                              },
                              expression: "$v.form.active.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.staffUse,
                              label: "Staff Use",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.staffUse.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.staffUse.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.staffUse, "$model", $$v)
                              },
                              expression: "$v.form.staffUse.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.autoAssign,
                              label: "Auto Assign",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.autoAssign.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.autoAssign.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.autoAssign, "$model", $$v)
                              },
                              expression: "$v.form.autoAssign.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$v.form.autoAssign.$model
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "Auto Assign for Trip Types",
                                  value: _vm.value.autoAssignTripTypes,
                                  items: _vm.tripTypes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  multiple: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item, parent }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pr-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return parent.selectItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" $delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  191486017
                                ),
                                model: {
                                  value: _vm.$v.form.autoAssignTripTypes.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.autoAssignTripTypes,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.form.autoAssignTripTypes.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.overrideRate,
                              label: "Override Trip Type & Event Rate?",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.overrideRate.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.overrideRate.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.overrideRate,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.overrideRate.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$v.form.overrideRate.$model,
                                expression: "$v.form.overrideRate.$model",
                              },
                            ],
                            attrs: {
                              value: _vm.value.vehicleRate,
                              label: "Vehicle Rate",
                              required: "",
                              type: "number",
                              min: "0",
                              "prepend-inner-icon": "mdi-currency-usd",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.vehicleRate
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.vehicleRate.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.vehicleRate.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.vehicleRate,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.vehicleRate.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.available,
                              label: "Available to End User on Trip",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.available.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.available.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.available, "$model", $$v)
                              },
                              expression: "$v.form.available.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }